<template>
  <div class="click-img-to-show-sentence-game layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <div class="game-content" :class="'lesson-' + lessonNO">
      <Star
        :totalStarNumber="optionList.length"
        :currentIndex="currentStarIndex"
      />
     
      <div class="background-area">
        <img :src="bgImg" />

        <template v-for="(item, index) in optionList">
          <transition name="el-fade-in" :key="item.id">
            <img
              :src="item.isShowCorrect ? item.clickImg : item.bgImg"
              class="bg-img-item"
              :class="{ fistImgOpacity: !item.isShowCorrect && noFirstImg }"
            />
          </transition>
        </template>
      </div>

      <div class="click-area">
        <div
          v-for="(item, index) in optionList"
          :key="item.id"
          :class="'click-area-' + item.id"
          @click="handleClickImg(item.id)"
        />
      </div>

      <div v-if="isShowMask == true" class="mask-area">
        <div
          class="mask-item"
        >
          <div class="choose-img-area" >
        <div class="img-area">
          <div class="wenzi">
            <div class="wenzi-content"
            >
              <div class="pinyin">
                {{optionList[currentId-1].pinyin}}
              </div>
              <div class="hanzi">
                {{optionList[currentId-1].hanzi}}
            </div>
          </div>
          </div>
          <div class="img-content "
          >
          <div class="container"
           v-for="(item, index) in maskImgList[currentId-1]"
          :key="item.id"
          >
             <img
              :src="item.bgImg"
              class="img-item"
              @click="handleChooseImg(item)"
            />
          </div>
          </div>
        </div>
      </div>

          
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { startConfetti, playCorrectSound } from "@/utils/tools";
import Star from "../Star";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
export default {
  name: "ClickImgToShowSentenceGame",
  props: {
    bgImg: {
      type: String,
      require: true,
    },
    // titleInfo: {
    //   type: Object,
    //   require: true,
    // },
    optionList: {
      type: Array,
      require: true,
    },
    maskImgList: {
      type: Array,
      require: true,
    },
    lessonNO: {
      type: Number,
      require: true,
    },
    noFirstImg: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  data() {
    return {
      idList: [],
      currentId: 0,
      currentStarIndex: 0,
      isShowMask: false,
      isShowSentence: false,
      isLastStep: false,
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "look",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },

      idSocketInfo: {},
      clickSocketInfo: {},
    };
  },
  components: {
    Star,
    PageButton,
  },
  watch: {
    idSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2006001,
          data: { value },
          text: "SpeakingSentence点击Menu socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    clickSocketInfo: {
      handler(value) {
        const data = {
          clickType: 2004602,
          data: { value },
          text: "SpeakingSentence点击Img socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },
  mounted() {
    this.$bus.$on("getIdSocket", ({ id }) => {
      this.handleClickImg(id, true);
    });
    // this.$bus.$on("showSentenceSocket", () => {
    //   this.handleShowSentence("socket", true);
    // });
  },
  beforeDestroy() {
    this.$bus.$off("getIdSocket");
    // this.$bus.$off("showSentenceSocket");
  },
  methods: {
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
    handleClickImg(id, isFromSocket = false) {
      if (!isFromSocket) {
        this.idSocketInfo = {
          id,
          value: Math.random(),
        };
      }
      if (this.idList.indexOf(id) != -1) {
        return;
      }
      console.log(123)
      this.isShowMask = true;
      this.currentId = id;
      console.log(this.currentId)
      this.idList.push(this.currentId);
        // playCorrectSound(true, false);
    },
   handleChooseImg(item){
     if(item.isTrue){
        playCorrectSound(true, false);
      this.currentStarIndex++;
        setTimeout(() => {
          this.isShowMask = false;
        }, 1000);
        if(this.currentStarIndex === this.optionList.length){
          this.isLastStep = true;
        startConfetti();
        playCorrectSound();
        }
     }else{
        playCorrectSound(false);

     }
   }
  },
};
</script>
<style lang="scss" scoped>
.click-img-to-show-sentence-game {
  background: #fff;
  border-radius: 48px;
  .game-content {
    width: 100%;
    height: 100%;
    // background: #f9f6e6;
    border-radius: 48px;
    position: relative;
    .background-area {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 48px;
      }
      .bg-img-item {
        position: absolute;
        top: 0;
        left: 0;
      }
      .fistImgOpacity {
        opacity: 0;
      }
    }
    .click-area {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .click-area-1 {
        display: block;
        position: absolute;
        width: 23%;
        height: 37%;
        top: 7%;
        left: 9%;
        z-index: 5;
        &:hover {
          cursor: pointer;
        }
      }
      .click-area-2 {
        display: block;
        position: absolute;
        z-index: 5;
        width: 19%;
        height: 15%;
        top: 56%;
        left: 5%;
        &:hover {
          cursor: pointer;
        }
      }

      .click-area-3 {
        display: block;
        position: absolute;
        z-index: 5;
        width: 8%;
        height: 12%;
        top: 40%;
        left: 52%;
        &:hover {
          cursor: pointer;
        }
      }
      .click-area-4 {
        display: block;
        position: absolute;
        z-index: 5;
        width: 23%;
        height: 10%;
        top: 28%;
        right: 5%;
        &:hover {
          cursor: pointer;
        }
      }
      .click-area-5 {
        display: block;
        position: absolute;
        z-index: 5;
        width: 23%;
        height: 10%;
        top: 28%;
        right: 5%;
        &:hover {
          cursor: pointer;
        }
      }
      .click-area-6 {
        display: block;
        position: absolute;
        z-index: 5;
        width: 23%;
        height: 10%;
        top: 28%;
        right: 5%;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .lesson-44 {
      width: 20%;
    }
    .lesson-45 {
      width: 20%;
    }
    .mask-area {
      z-index: 10;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 48px;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      .mask-item {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        .mask-img {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer;
            border-radius: 58px;
          }
        }
        .sentence {
          position: absolute;
          // width: 70%;
          text-align: center;
          background: #cd4c3f;
          padding: 15px 6%;
          color: #fff;
          border-radius: 20px;
          bottom: 8%;
          margin: auto 0;
        }
        .showSentence {
          opacity: 1;
        }
      }
      .choose-img-area {
      height: 72%;
      width:70%;
      display: flex;
      justify-content: center;
        align-items: center;
      margin-top:6%;
      .img-area {
        // margin-left: 22%;
        margin-top: 10%;
          width: 70%;
        height:100%;
        display: flex;
        flex-direction:column;
        // justify-content: center;
        align-items: center;
         background: white;
        border-radius: 28px;
        .wenzi{
            background: #CD4C3F;
            border-radius: 28px 28px 0 0;
            width:100%;
            height:120px;
            color:white;
            display: flex;
            justify-content: center;
            align-items: center;
            // position: relative;
        .wenzi-content{
          // position: absolute;
          // left:70px;
          // top:30%;
          .hanzi{
            font-size:32px;
          }
        }
        }
        .img-content {
          width: 80%;
          margin-top:8%;
          // height: 80%;
          display: flex;
           justify-content: space-between;
          align-items: center;
         .container{
             margin: 10px;
            //  border:10px solid #a53d32;
         } // // flex-wrap: wrap;
          .img-item {
            width: 100%;
            border-radius: 18px;
            display: block;
            box-sizing: border-box;
            cursor: pointer;
              // border-left: 10px solid #f6c752;
             border-bottom: 15px solid #a53d32;
            &:hover {
              border-bottom: 15px solid #f6c752;
              // border-left: 10px solid #f6c752;
        }
      }
        }
      }
    }
    }
  }
  .lesson-292 {
    .click-area {
      .click-area-1 {
        // background:blue;
        // opacity:0.5;
        width: 9%;
        height: 11%;
        top: 17%;
        left: 40%;
      }
      .click-area-2 {
        // background:blue;
        // opacity:0.5;
        width: 12%;
        height: 10%;
        top: 36%;
        left: 8%;
      }

      .click-area-3 {
        // background:blue;
        // opacity:0.5;
        width: 16%;
        height: 7%;
        top: 8%;
        left: 32%;
      }
      .click-area-4 {
        // background:blue;
        // opacity:0.5;
        width: 14%;
        height: 15%;
        top: 67%;
        left: 32%;
      }
      .click-area-5 {
        // background:blue;
        // opacity:0.5;
        width: 13%;
        height: 10%;
        top: 43%;
        left: 68%;
      }
    }
  }

}
</style>
