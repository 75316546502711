var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "click-img-to-show-sentence-game layout-border" },
    [
      _c("PageButton", {
        attrs: {
          bottomCornerButtonList: _vm.bottomCornerButtonList,
          isLastStep: _vm.isLastStep,
        },
        on: { clickNext: _vm.nextPage },
      }),
      _c(
        "div",
        { staticClass: "game-content", class: "lesson-" + _vm.lessonNO },
        [
          _c("Star", {
            attrs: {
              totalStarNumber: _vm.optionList.length,
              currentIndex: _vm.currentStarIndex,
            },
          }),
          _c(
            "div",
            { staticClass: "background-area" },
            [
              _c("img", { attrs: { src: _vm.bgImg } }),
              _vm._l(_vm.optionList, function (item, index) {
                return [
                  _c(
                    "transition",
                    { key: item.id, attrs: { name: "el-fade-in" } },
                    [
                      _c("img", {
                        staticClass: "bg-img-item",
                        class: {
                          fistImgOpacity: !item.isShowCorrect && _vm.noFirstImg,
                        },
                        attrs: {
                          src: item.isShowCorrect ? item.clickImg : item.bgImg,
                        },
                      }),
                    ]
                  ),
                ]
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "click-area" },
            _vm._l(_vm.optionList, function (item, index) {
              return _c("div", {
                key: item.id,
                class: "click-area-" + item.id,
                on: {
                  click: function ($event) {
                    return _vm.handleClickImg(item.id)
                  },
                },
              })
            }),
            0
          ),
          _vm.isShowMask == true
            ? _c("div", { staticClass: "mask-area" }, [
                _c("div", { staticClass: "mask-item" }, [
                  _c("div", { staticClass: "choose-img-area" }, [
                    _c("div", { staticClass: "img-area" }, [
                      _c("div", { staticClass: "wenzi" }, [
                        _c("div", { staticClass: "wenzi-content" }, [
                          _c("div", { staticClass: "pinyin" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.optionList[_vm.currentId - 1].pinyin
                                ) +
                                " "
                            ),
                          ]),
                          _c("div", { staticClass: "hanzi" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.optionList[_vm.currentId - 1].hanzi
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "img-content" },
                        _vm._l(
                          _vm.maskImgList[_vm.currentId - 1],
                          function (item, index) {
                            return _c(
                              "div",
                              { key: item.id, staticClass: "container" },
                              [
                                _c("img", {
                                  staticClass: "img-item",
                                  attrs: { src: item.bgImg },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleChooseImg(item)
                                    },
                                  },
                                }),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }